.objectifsImages {
    width: 70%;
    height: 100%;
    object-fit: cover; 
  }
  
  .objectifsRow {
    margin-block: 1rem;
    display: flex;
    justify-content: center;
  }
  
  .objectifsCard {
    width: 15rem;
    margin: 0.5rem;
    text-align: center;
  }

  .formObjectif{
    background-color: white;
    padding: 1rem;
    border-radius:0.5rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);


  }

  .containerChildDetails {
    display: flex;
    flex-direction: column;
  
    border-radius: 0.6875rem;
    border: 1px solid #d2eeff;
    background: #f2faff;
    padding-top: 1rem;
    margin-block: 1rem;
    padding-inline: 0.5rem;
    width: 100%;
  }
  .label {
    color: #757474;
    font-size: 1rem !important;
    margin-bottom: 0.5rem;
  }
  .iconRemove {
    top: 0.6rem;
    right: 0.5rem;
    font-size: 1.25rem;
    color: #3a3a3a;
  }
  .containerCard {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .deleteIcon {
    display: flex;
    justify-content: end;
  }
  
  .headerStyle {
    color: #205980 !important;
    font-size: 1rem;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
  }
  .container {
    display: flex;
    flex-direction: column;
  
    border-radius: 0.6875rem;
    border: 0.5px solid #c7d2dc;
    background: white;
    padding-block: 0.5rem;
    margin-block: 1rem;
    width: 100%;
    box-shadow: 0px 2px 6px 0px rgba(30, 30, 30, 0.25);
  }
  