.rowadresse {
    padding-bottom: 2rem;
  }
  
  .sujet {
    padding-bottom: 2rem;
  }
  
  .wrapperClassName {
    background-color: rgba(218, 218, 218, 0.288);
    border-radius: 10px;
    margin-block: 1rem;
    
    /* min-height: 10rem; */
  }
  
  .toolbarClassName {
    border-radius: 10px;
  }
  
  .toolbarClassName > div {
    padding: 1px;
  }
  